<template>
  <div class="margin-wrap">
    <slot />
  </div>
</template>

<style scope>
.margin-wrap {
  width: 100%;
  height: 100%;
  @apply py-10 my-2;
}
</style>
